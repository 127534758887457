.menu.social-media {
  a:hover i {
    &.facebook {
      color: #1778f2;
    }
    &.twitter {
      color: #1da1f2;
    }
    &.instagram {
      color: #e1306c;
    }
    &.youtube {
      color: #ff0000;
    }
  }
}
