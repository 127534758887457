.ui.datatable-grid {
    .datatable-tools {
        padding-top: unset;
        padding-bottom: unset;
    }
    .ui.segment.datatable {
        padding: unset;
        .ui.table {
            .table-head {
                .icon {
                    margin-left: 0.3rem;
                    font-size: 0.9rem;
                }
            }
            td {
                &.image {
                    img {
                        width: 3.75rem;
                        height: 3.75rem;
                    }
                }
            }
        }
    }
}
.ui.grid > .row.datatable-container {
    padding-top: unset;
}
