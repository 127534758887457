.ui.attachment.segment {
    &.loading:before {
        border-radius: 500rem;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
    }
    .image-form-input {
        display: none;
    }
    .image {
        width: 200px;
        max-width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #a0a1a0;
    }
}
