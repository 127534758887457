@font-face {
  font-family: 'trotmregular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/trotm-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/trotm-regular-webfont.woff') format('woff'), /* Modern Browsers */
       url('../fonts/trotm-regular-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/trotm-regular-webfont.otf') format('otf');
}

.trotm {
  font-family: trotmregular;
  font-weight: normal;
  font-style: normal;
}

@keyframes ANIMATE-RED {
   0% {
      transform: translate(0, 0)
   }
   50% {
      transform: translate(3px, 3px)
   }
   100% {
      transform: translate(0, 0)
   }
}

@keyframes ANIMATE-BLUE {
   0% {
      transform: translate(0, 0)
   }
   50% {
      transform: translate(-3px, -3px)
   }
   100% {
      transform: translate(0, 0)
   }
}

.channel-split-wrapper {
   position: relative;
}

.channel-split-static {
   opacity: 0;
}

.channel-split {
   position: absolute;
   top: 0;
   left: 0;
   mix-blend-mode: difference;
}

.channel-split-red {
   color: #f00;
   animation: ANIMATE-RED 8000ms infinite;
}

.channel-split-green {
   color: #0f0;
}

.channel-split-blue {
   color: #00f;
   animation: ANIMATE-BLUE 8000ms infinite;
}
