$spotify-color: #1bd75f;

.violet-background {
    background-color: #c90e45;
}
.toast-container {
    .row .column .ui.image {
        max-width: unset;
        width: 2em;
    }
}
.ui.button {
    &.play-button:not(.circular) {
        color: white;
        background-color: #1eb954;
        padding: .78571429em 2.8em .78571429em;
        &:hover {
            background-color: $spotify-color;
        }
    }
    &.add-button {
        &.separated {
            margin: 0 1.2em;
        }
        &:not(.circular) {
            box-shadow: 0 0 0 0px!important;
            &:hover, &:focus {
                box-shadow: 0 0 0 0px!important;
            }
            i.icon {
                font-size: 1.4em;
            }
        }
        i.icon {
            width:1.1em;
        }
    }
}
.ui.modal.spotify-devices-modal {
    @media only screen and (max-width: 767px) {
        width: 65%;
    }
    .content {
        min-height: 17rem;
        background-color: #272827;
        .row {
            &.devices-image-container {
                padding-bottom: 2rem;
                img {
                    width: 200px;
                }
            }
            &.device-container {
                &:last-child {
                    padding-bottom: 1.5rem;
                }
                cursor: pointer;
                .column {
                    padding-left: 2.5rem;
                }
                .ui.header {
                    .icon {
                        font-weight: 100;
                        font-size: 1.5rem;
                        margin-right: 1rem;
                    }
                }
                &:hover {
                    .ui.header {
                        color: $spotify-color;
                        .icon {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
