.ui.form {
    .fields.sub-group {
        width: 100%;
        padding: 2.2em;
        margin: unset;
        display: inline-block;
        .field {
            margin-bottom: 0.75rem;
        }
    }
}
