@import "shop/product-detail";

.ui.card.product-card {
    .content {
        padding: .6em 1em;
        .category {
            font-size: 0.8em;
        }
        .header {
            padding-top: 0.35rem;
            font-size: 1em;
        }
    }
}
.color-picker {
    .row.colors {
        .radio.checkbox.color {
            label {
                &:before {
                    width:25px;
                    height:25px;
                }
                &:after {
                    width:35px;
                    height:35px;
                    top: -4px;
                    left: -5px;
                    opacity: unset;
                    border: 1px solid #dcdfdf;
                }

            }
            &.red { label:after {background-color: #da2828;} }
            &.green { label:after {background-color: green;} }
            &.blue { label:after {background-color: blue;} }
            &.yellow { label:after {background-color: yellow;} }
            &.checked {
                label:before {border: 2px solid #f44ce4;}
            }
        }
    }
}

.product-price {
    color: #4e54c8;
    font-size: 1.25rem;
    &.big {
        font-size: 2rem;
        .cents { font-size: 1.25rem; }
    }
    .cents {
        font-size: 1rem;
    }
}
.ui.accordion.detail-product-information {
    .icon.dropdown {
        float: right;
    }
    .shipping {
        .price {

        }
        .content.method {
            padding: .5em 1em .5em;
            .header {
                color: #000!important;
                font-weight: 100;
                font-size: 14px;
                font-family: "Open Sans";
                padding-bottom: 5px;
            }
            .description {
                font-size: 12px;
                color: gray;
            }
        }
    }
}
