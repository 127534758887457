.row.product-detail {
    .column.bullets.preview {
        img {
            margin-bottom: 1em;
            border: 1px solid #dcdfdf;
        }
    }
    .description {
        .container {
            padding-bottom: 1.25rem;
        }
        .text-colors {
            color: grey;
            b { color: black;}
        }
    }
}
