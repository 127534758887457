.ui {
    &.segment.main-container {
        &.home-background.basic {
            color: white;
            background-size: cover;
        }
        .main-wrapper {
            .cover-container {
                .cover {
                    max-width: 75%;
                }
            }
            .trotm-info {
                h1 {
                    font-size: 48px;
                }
                p {
                    margin-top: 1em;
                    font-size: 16px;
                }
            }
        }
    }
}
