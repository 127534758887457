.recording-container {
    .recording-metadata {
        @media only screen and (max-width: 767px) {
            .recording-name, .recording-type, .actions-container, .recording-info {
                margin-left: 1.2rem!important;
            }
        }
        .recording-type {
            color: white;
            margin: unset;
        }
        .recording-name {
            margin-top: 1rem;
            font-size: 3rem
        }
        .recording-info {
            color: #b2b3b2;
        }
    }
    .recording-description {
        color: white;
        padding: 1.5rem 0;
        font-size: 1.14285714rem;
        line-height: 1.6;
    }
}
.streaming-platforms.menu {
    a:hover i {
        &.spotify {
          color: #1bd75f;
        }
        &.youtube {
          color: #ff0000;
        }
        &.amazon {
          color: #fe9902;
        }
        &.apple {
          color: #f35b71;
        }
    }
}
