.product-slider  {
    .awssld__wrapper{
        border: 1px solid #dcdfdf;
        border-radius: .3125em;
    }
    --slider-height-percentage: 120%;
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #e9e8eb;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #05A7E2;
    --control-bullet-active-color: #005FB9;
    --loader-bar-color: #05A7E2;
    --loader-bar-height: 3px;
    --content-background-color: white;
    .awssld__bullets {
        button {
            height: 12px;
            width: 12px;
        }
    }
    .awssld--fill-parent {

    }
}
